/* eslint-disable no-console,no-undef */
import React, { Component } from 'react';
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from 'react-redux';
import Datetime from 'react-datetime';
import { withTranslation } from 'react-i18next';


import {
  Layout, FormContainer, FormSection, StyledDateTime,
  NextButton, DownArrow, ButtonContainer, ErrorMessageContainer, RadioButtonContainer, HeaderBox, UploadPhotoContainer, InputContainer, StyledLabel, StyledInputV2, InputImage, Title, RemoveImage
} from './styles';
import { ErrorMessage } from '../LoginPage/styles';

import { CustomDropDown, CustomMenuItem } from '../RegistrationGeneralHealth/styles';

import FormWizardSteps from '../FormWizardSteps';

import withValidations from "../WithValidations";
import { getUserInformation, signUpError, saveUserInformation } from '../../redux/actions';

// import { registrationFormFields } from '../../../mockData.json';
import RadioButton from "../../components/CustomRadioButton";
import { setSpouseDependentInfo } from "../../redux/actions/registerActions";
import { checkImage, getOrientation, resetOrientation } from '../../utils/methods';
import { imageErrorMessage } from '../../utils/constants';
import { toast } from 'react-toastify';


class RegistrationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDatePicker: false,
      birthDate: '',
      count: 0,
      selectedOption: props.selectedUserType,
      imgSrc: null

    };
  }

  registrationFormFields= [
    {
      "title": "first",
      "inputFields": [
        { "type": "text", "placeholder": "First Name", "name": "firstName", "maxLength": 30,"label":"First Name","required":true},
        { "type": "text", "placeholder": "Last Name", "name": "lastName", "maxLength": 30,"label":"Last Name","required":true},
        { "type": "text", "placeholder": "Email", "name": "email", "maxLength": 50,"label":"Email","required":true},
        {"type": "text", "placeholder": "Confirm Email", "name": "confirmEmail", "maxLength": 50,"label":"Confirm Email","required":true},
        { "type": "password", "placeholder": "Enter Password", "name": "password", "maxLength": 200,"label":"Password","required":true},
        { "type": "password", "placeholder": "Enter Confirm Password", "name": "confirmPassword", "maxLength": 200,"label":"Confirm Password","required":true},
        { "name": "date","label":"Birthday","required":false},  { "type": "number", "placeholder": "Enter Phone Number", "name": "phoneNumber", "maxLength": 12,"label":"Phone No.","required":false}

      ]
    }
  ]

  onChangeImg = (e) => {
    let file = e.target.files[0];
    if (checkImage(e.target.files)) {
      if ((file?.size / 1000000) <= 20) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          this.setState({
            imgSrc: reader.result,
            hide: true
          });
          getOrientation(file, (or) => {
            resetOrientation([reader.result], or, (baseImage) => {
              this.props.saveUserInformation('profile', baseImage)

              this.setState({
                imgSrc: baseImage,
                hide: false
              });
            });
          });
        };
      } else {
        toast.error('Please select image file less than 20MB');
        document.getElementById('uploadPhoto').value = '';
      }
    } else {
      toast.error(imageErrorMessage);
      document.getElementById('profle-file').value = '';
    }
  };

  handelRemove = () => {
    this.setState({ imgSrc: null })
    this.props.saveUserInformation('profile', null)

  }

  componentDidMount() {
    const { getUserInformation, userData, signUpError, companyId, history } = this.props;
    if (companyId < 0) {
      history.push('/user');
    }
    getUserInformation();
    signUpError();
    this.setState({
      birthDate: userData.birthday
    });
    document.addEventListener('mousedown', this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
  }

  handleClick = (e) => {
    if (this.datePickerWrapper && !this.datePickerWrapper.contains(e.target)) {
      this.setState({
        openDatePicker: false
      });
    }
  };

  setDatePickerWrapper = (node) => {
    this.datePickerWrapper = node;
  };

  showDatePicker = () => {
    this.setState((prev) => ({
      openDatePicker: !prev.openDatePicker
    }))
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { history } = this.props;
    history.push('/user/step2');
  };

  changeDate = (date) => {
    this.props.saveUserInformation('birthday', date)
    this.setState({
      birthDate: date,
      openDatePicker: false
    });
  };

  onDateBlur = (date) => {
    const { saveUserInformation } = this.props;
    saveUserInformation('birthday', date)
    this.setState({ openDatePicker: false });
  };

  onBlur = (e) => {
    const { saveUserInformation, errors } = this.props;
    if (e.target.name == 'email') {
      if (!errors[e.target.name] && e.target.value.trim() !== '') saveUserInformation(e.target.name, e.target.value.trim());
    } else {
      if (!errors[e.target.name] && e.target.value.trim() !== '') saveUserInformation(e.target.name, e.target.value);
    }
  };

  getMethod = (e) => {
    const { saveUserInformation } = this.props;
    if (e.target.name == 'email' || e.target.name == 'confirmEmail') {
      saveUserInformation(e.target.name, e.target.value.trim());
    } else {
      saveUserInformation(e.target.name, e.target.value);
    }
  }

  closeDate = (e) => {
    if (e.target.className === 'form-control') {
      if (this.state.count > 0 && e.target.className === 'form-control' && this.state.openDatePicker) {
        this.setState({ openDatePicker: false })
      }
      else {
        this.setState({ count: 1 })
      }
    }
  }


  changeOpen = (index) => {
    if (index == this.state.open) {
      this.setState({ open: '' })
    }
    else {
      this.setState({ open: index });
    }
  }

  renderCompanyLocationDropDown = (item, dropDownValues, keyValue) => {
    const { saveUserInformation, userData } = this.props;
    const locationIndex = dropDownValues.findIndex((location) => location.id === userData[keyValue]);
    let selectedValue = null;
    if (locationIndex > -1) {
      selectedValue = dropDownValues[locationIndex][keyValue];
    }
    const ShowTitle = 1;
    return (
      <CustomDropDown
        title={selectedValue ? selectedValue : item.title}
        key={item.title}
        id={keyValue}
        onClick={() => this.changeOpen(keyValue)}
        opened={keyValue === this.state.open ? 1 : 0}
        onBlur={() => this.changeOpen('')}
        company={1}
      >
        {ShowTitle && <CustomMenuItem key={item.title + 'header'} disabled>{item.title}</CustomMenuItem>}
        {
          dropDownValues.map((value, index) => (
            <CustomMenuItem
              eventKey={value.location + index} key={value[keyValue] + index}
              onSelect={() => saveUserInformation(keyValue, value.id)}
            >
              {value[keyValue]}
            </CustomMenuItem>
          ))
        }
      </CustomDropDown>
    )
  };

  checkUserData = () => {
    const { userData, locationDetails, departmentDetails } = this.props;
    if (!locationDetails && !departmentDetails) {
      return Object.keys(userData).length < 6;
    } else if (!locationDetails || !departmentDetails) {
      return Object.keys(userData).length < 7;
    } else if (locationDetails && departmentDetails) {
      return Object.keys(userData).length < 8;
    } else {
      return Object.keys(userData).length < 6;
    }
  };

  toggleRadioButton = (e) => {
    this.setState({ selectedOption: e.target.value }, () => {
      this.saveSpouseDependentInfo();
    });
  };

  saveSpouseDependentInfo = () => {
    const { selectedOption } = this.state;
    const { setSpouseDependentInfo } = this.props;
    setSpouseDependentInfo(selectedOption == 'employee' ? 0 : selectedOption == 'spouse' ? 1 : selectedOption == 'dependent' ? 2 : null, selectedOption);
  };

  render() {
    const { openDatePicker, birthDate, selectedOption } = this.state;
    const { onChange, errors, touched, userData, error, history, isSpouseDependent, t, companyId, isSpouse, isDependent } = this.props;
    const yesterday = Datetime.moment().subtract(13, 'years');
    const previousLimit = Datetime.moment().subtract(100, 'years');
    const valid = (currentDate) => currentDate.isBefore(yesterday) && currentDate.isAfter(previousLimit);
    return (
      <Layout>


        <FormWizardSteps history={history} activeStep={1} errors={errors} touched={touched} />
        <Title>{t("Login Credentials")}</Title>
        {error &&
          <ErrorMessageContainer>
            <ErrorMessage>{error}</ErrorMessage>
          </ErrorMessageContainer>
        }
        <FormContainer >
          <HeaderBox none={true}>
            <h1>{t("Let's get started by getting to know you")}</h1>
            <h3 >{t("Your personal information such as first name, last name email, phone, and picture will be visible to your clients as a means to contact you.")}</h3>
          </HeaderBox>
          <form onSubmit={this.onSubmit}>
            {companyId===102||companyId===103?null:<RadioButtonContainer>
              <p>{t("Select User Type")} *</p>
              <div>
                <RadioButton challengeLeaderBoard={true} id="1" handler={this.toggleRadioButton} value={'employee'} isSpouseDependent={isSpouseDependent} isChecked={selectedOption === 'employee'} label={'Employee'} /> 
                {isSpouse === 1 ? <RadioButton challengeLeaderBoard={true} id="2" handler={this.toggleRadioButton} value={'spouse'} isSpouseDependent={isSpouseDependent} isChecked={selectedOption === 'spouse'} label={'Spouse'} /> : null}
                {isDependent === 1 ? <RadioButton challengeLeaderBoard={true} id="3" handler={this.toggleRadioButton} value={'dependent'} isSpouseDependent={isSpouseDependent} isChecked={selectedOption === 'dependent'} label={'Dependent'} /> : null}
              </div></RadioButtonContainer>}

            <UploadPhotoContainer>
              <div className='innerDivImageContainer'>
                <img alt='profilre-img' src={this.state.imgSrc ? this.state.imgSrc : "/public/images/default.png"} ></img>
                {this.state.imgSrc == null ? <InputImage className="edit-profile-pic " imageReload={1}>
                  <i className="addImg">{t("Upload Image")}</i>
                  <input
                    id="profle-file"
                    type="file"
                    name="user-profile"
                    multiple={false}
                    accept=".jpeg, .png, .jpg"
                    onChange={(e) => this.onChangeImg(e)}
                    onClick={(e) => e.target.files[0] && this.onChangeImg(e)}
                  />
                </InputImage> :
                  <RemoveImage onClick={this.handelRemove}>{t("Remove Image")}</RemoveImage>}
              </div>
            </UploadPhotoContainer>
            {
              this.registrationFormFields.map((section, index) => (
                <FormSection isRegistrationForm={1} key={section.title + index} >
                  {
                    section.inputFields.map((field, index) => {
                      let fieldValue = '';
                      Object.keys(userData).map((value) => {
                        if (value === field.name) {
                          fieldValue = userData[value];
                        }
                        if (value === field.name && field.name === 'confirmPassword') {
                          fieldValue = userData.password;
                        }
                      });
                      if (field.name === 'date') {
                        return (
                          <InputContainer onClick={(e) => this.closeDate(e)} style={{ position: 'relative' }} key={field.name + index}  >
                            <StyledLabel>{t(field.label)}<span> *</span></StyledLabel>

                            <StyledDateTime
                              inputProps={{ placeholder: "Select DOB", readOnly: true }}
                              open={openDatePicker}
                              dateFormat="MM/DD/YYYY"
                              closeOnSelect={true}
                              closeOnTab={true}
                              timeFormat={false}
                              onChange={this.changeDate}
                              value={birthDate}
                              isValidDate={valid}
                              viewDate={birthDate ? birthDate : yesterday}
                              onBlur={this.onDateBlur}
                              onFocus={this.showDatePicker}
                              viewMode="years"
                            />
                            <DownArrow opened={openDatePicker}
                              onClick={this.showDatePicker}
                            >
                              <img alt="calender" src="/public/images/calendar.svg"></img>
                            </DownArrow>
                          </InputContainer>
                        )
                      }
                      return (
                        <InputContainer key={field.name + index} fullWidth={field.label === 'Your Message'}
                          padding={index % 2 === 0}>
                          <StyledLabel>{t(field.label)} {field.required && <span>*</span>}</StyledLabel>
                          <StyledInputV2
                            type={field.type}
                            placeholder={t(field.placeholder)}
                            name={field.name}
                            onChange={(e) => onChange(e, this.getMethod)}
                            defaultValue={fieldValue}
                            onBlur={(e) => this.onBlur(e)}
                            maxLength={field.maxLength}
                          />
                          {errors[field.name] && <ul><ErrorMessage className="error-message">{errors[field.name]}</ErrorMessage></ul>}
                        </InputContainer>
                      )
                    })
                  }
                </FormSection>
              ))
            }

          </form>
          <ButtonContainer>
            <NextButton
              onClick={this.onSubmit}
              disabled={
                Object.keys(_.omitBy(errors, _.isNil)).length > 0 ||
                ((Object.keys(touched).length === 0 ||
                  Object.keys(touched).length < 6) &&
                  this.checkUserData()) || _.isNil(birthDate)
              }
            >
              {t("Next")}
              <i className="fas fa-chevron-right" />
            </NextButton>
          </ButtonContainer>
        </FormContainer>

      </Layout>
    )
  }
}

RegistrationForm.propTypes = {
  history: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  touched: PropTypes.object,
  error: PropTypes.string,
  userData: PropTypes.object.isRequired,
  getUserInformation: PropTypes.func.isRequired,
  signUpError: PropTypes.func.isRequired,
  saveUserInformation: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  locationDetails: PropTypes.array,
  departmentDetails: PropTypes.array,
  setSpouseDependentInfo: PropTypes.func,
  isSpouseDependent: PropTypes.number,
  isSpouse: PropTypes.number,
  isDependent: PropTypes.number,
  selectedUserType: PropTypes.string,
  t: PropTypes.func

};

const mapStateToProps = (state) => ({
  userData: state.register.userData,
  error: state.register.error,
  locationDetails: state.register.locationDetails,
  departmentDetails: state.register.departmentDetails,

});

const mapDispatchToProps = (dispatch) => ({
  getUserInformation: () => dispatch(getUserInformation()),
  signUpError: () => dispatch(signUpError()),
  saveUserInformation: (name, value) => dispatch(saveUserInformation(name, value)),
  setSpouseDependentInfo: (bool, selectedUser) => dispatch(setSpouseDependentInfo(bool, selectedUser)),
});

export default withValidations(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RegistrationForm)));